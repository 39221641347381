import React, { useMemo, useState } from 'react';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import styled from 'styled-components';
import { Button } from '@naf/button';
import { spacing } from '@naf/theme';
import EmptySearchResultElprix from '../../../../assets/images/EmptySearchResultElprix.svg?url';
import { ShowcaseBlockType } from '../../../../../types/showcaseBlockType';
import { CombinedResultCard } from './CombinedResultCard';
import { Filter } from './Filter';
import S from '../../../styles/bilguide/tests/StyledTests';
import { SectionBlocks } from '..';

interface Props {
  block: ShowcaseBlockType;
}

const CONTENT_BREAK = 6;

export const ShowcaseBlock: React.FC<Props> = ({ block }) => {
  const { title, ingress, data, extraContent, allCategories } = block;
  const { testedVehicles, testDate, season, tests } = data;
  const [filteredVehicles, setFilteredVehicles] = useState(testedVehicles);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState<string[]>([]);

  // Only include tags that is included on at least one vehicle
  const allCategoriesWithVehicles = useMemo(
    () => allCategories.filter(({ id }) => testedVehicles.some((d) => d.categories?.some((v) => v.id === id))),
    [allCategories, testedVehicles],
  );

  const constructEmptySearchExplanation = () => {
    const filterString =
      searchTerm !== ''
        ? [searchTerm, ...allCategories.filter((c) => searchFilter.includes(c.id)).map((c) => c.category.toLowerCase())]
        : [...allCategories.filter((c) => searchFilter.includes(c.id)).map((c) => c.category.toLowerCase())];

    if (filterString.length === 1) {
      return `Vi fant dessverre ingen treff på ${filterString[0]} for El Prix sommer 2023`;
    }
    return `Vi fant dessverre ingen treff på ${filterString.slice(0, -1).join(', ')} og ${
      filterString[filterString.length - 1]
    } for El Prix sommer 2023`;
  };

  return (
    <ShowcaseBlockWrapper>
      <HeaderText variant={TextVariant.Header1}>{title}</HeaderText>
      {ingress && <Text variant={TextVariant.Ingress}>{ingress}</Text>}
      <S.HRLine />
      <Filter
        data={testedVehicles}
        categories={allCategoriesWithVehicles}
        setData={setFilteredVehicles}
        setSearchTerm={setSearchTerm}
        setFilters={setSearchFilter}
        tests={tests}
      />
      <Grid>
        {filteredVehicles.length > 0 ? (
          filteredVehicles.map((vehicleData, i) => (
            <React.Fragment key={vehicleData.vehicleId}>
              <GridCol s="12" m="12" l="6" xl="6">
                <CombinedResultCard data={vehicleData} context={{ season, testDate, tests }} />
              </GridCol>
              {extraContent && extraContent.length > 0 && (i + 1) % CONTENT_BREAK === 0 && (
                <GridCol s="12" m="12" l="12" xl="12">
                  <SectionBlocks content={[extraContent[((i + 1) / CONTENT_BREAK - 1) % extraContent.length]]} />
                </GridCol>
              )}
            </React.Fragment>
          ))
        ) : (
          <GridCol s="12" m="12" l="12" xl="12">
            <EmptyState>
              <img src={EmptySearchResultElprix} alt="" />
              <Text variant={TextVariant.Header3}>{constructEmptySearchExplanation()}</Text>
              <Text variant={TextVariant.ArticleText}>
                Vi har testet mange biler opp gjennom årene.
                <br /> Se om du finner bilden du leter etter i Elbilguiden
              </Text>
              <Button
                variant="spruce"
                target="_blank"
                href={`https://www.naf.no/bilguiden/elbiler-i-norge?query=${searchTerm}`}
              >
                Søk i Elbilguiden
              </Button>
            </EmptyState>
          </GridCol>
        )}
      </Grid>
    </ShowcaseBlockWrapper>
  );
};

const EmptyState = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  > h3,
  p {
    max-width: 460px;
    margin: 0;
  }

  > h3 {
    margin: ${spacing.space16} 0 ${spacing.space8} 0;
  }

  > a {
    margin-top: ${spacing.space40};
  }
`;

const HeaderText = styled(Text)`
  margin-top: 0;
`;

// Allows this block to be selected by nesting styled-components
export const ShowcaseBlockWrapper = styled.div``;
